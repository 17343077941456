function toggleEligibilityCheckModal() {
    const modal = document.querySelector('#checkEligibilityModal');
    if (!modal) return;
    Magellan.Modal.open(modal);
}

function showMandatoryEligibilityModal() {

    const targetHref = window.location.pathname;

    callEligibilityModal(targetHref);

    const closeButton = document.querySelector('#checkEligibilityModal .modal-close');
    if(closeButton) {
        closeButton.remove();
    }

    document.addEventListener('keyup',function(e){
        if (e.key === "Escape") {
            const modal = document.querySelector('#checkEligibilityModal');
            modal.style.display = 'block';
        }
    });
}

function addEligibilityModalListener() {
    const navigationLinks1 = document.querySelectorAll('.magellan-bhverified-item');

    if (navigationLinks1) {
        navigationLinks1.forEach(navigationLink => {

            navigationLink.addEventListener('click', (event) => {
                    event.preventDefault();
                    const targetHref = event.target?.href || event.currentTarget?.href;
                    callEligibilityModal(targetHref);
            })
        })
    }
}

function callEligibilityModal(targetHref) {

    toggleEligibilityCheckModal();

    if (targetHref) {
        const confirmAndReviewButton = document.querySelector('#checkEligibilityModal .check-eligibility-modal-review-button');

        const appendRedirectToSearchParams = (href, targetHref) => {
            const attributeValue = targetHref.replace(
                `${themeDisplay.getMagellanDefaultURL()}`,
                ''
            );
            const urlObject = new URL(href);
            const urlSearchParams = new URLSearchParams(urlObject.search);
            urlSearchParams.set('redirectUrl', attributeValue);
            urlObject.search = urlSearchParams.toString();
            return urlObject.toString();
        }

        confirmAndReviewButton.href = appendRedirectToSearchParams(confirmAndReviewButton.href, targetHref);

    }
}

(function () {

    addEligibilityModalListener();

    if(Liferay.ThemeDisplay.bhVerifiedArticle() && themeDisplay.isSignedIn() && themeDisplay.isIntegratedUser() && !themeDisplay.bhVerified()) {
        showMandatoryEligibilityModal();
    }

    if (themeDisplay.isSignedIn() && themeDisplay.isIntegratedUser() && !themeDisplay.bhVerified()) {
        const protectedLinksUrl = themeDisplay.bhProtectedURLs();
        const navigationLinks = Array.from(document.querySelectorAll('a'));

        if (navigationLinks) {
            const filteredLinks = navigationLinks.filter((anchor) => {
                const lastItem = anchor.href.substring(anchor.href.lastIndexOf('/'));
                return protectedLinksUrl.includes(lastItem);
            });

            filteredLinks.forEach(navigationLink => {

                navigationLink.addEventListener('click', (event) => {

                    event.preventDefault();

                    toggleEligibilityCheckModal();

                    const confirmAndReviewButton = document.querySelector('#checkEligibilityModal .check-eligibility-modal-review-button');

                    const targetHref = event.target?.href || event.currentTarget?.href;

                    if (targetHref) {

                        const appendRedirectToSearchParams = (href, targetHref) => {
                            const attributeValue = targetHref.replace(
                                `${themeDisplay.getMagellanDefaultURL()}`,
                                ''
                            );
                            const urlObject = new URL(href);
                            const urlSearchParams = new URLSearchParams(urlObject.search);
                            urlSearchParams.set('redirectUrl', attributeValue);
                            urlObject.search = urlSearchParams.toString();
                            return urlObject.toString();
                        }

                        confirmAndReviewButton.href = appendRedirectToSearchParams(confirmAndReviewButton.href, targetHref);

                    }

                });
            });
        }
    }
})();